<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field"/>
      <el-row :gutter="30" type="flex">
      <el-col :span="12" >
        <div class="form-group">
    <label>Date Stamp</label>
    <div class="d-gird-auto-100 mt-1">
      <div class="form-group" >
                    <el-radio v-model="field.datestamp" :label="true">Yes</el-radio>
                    <el-radio v-model="field.datestamp" :label="false">No</el-radio>
                </div>    
        </div></div>
       
            </el-col>
            <el-col :span="12">
          <el-form-item label="Value" v-if="!field.datestamp">
            <span style="color:red; font-size: 20px;">* </span>
            <el-date-picker
              v-model="field.value"
              placeholder="Pick a day"
              type="date"
              :format="getFormat()"
            >
            </el-date-picker>
            <FormError error-key="value" />
          </el-form-item>
        </el-col>
            </el-row>

      <el-row :gutter="30" type="flex" class="mt-1">
      <el-col :span="12">
          <div class="form-group">
            <Tag :field="field"/>
          </div>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="50">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Validations">
              <el-date-picker
                type="date"
                placeholder="Min date"
                v-model="field.validations.min"
                style="width: 100%;"
                format="yyyy-dd-MM"
              ></el-date-picker>
              <el-date-picker
                type="date"
                placeholder="Max date"
                v-model="field.validations.max"
                style="width: 100%;"
                format="yyyy-dd-MM"
              ></el-date-picker>
            </el-form-item>
          </div>
        </el-col>
      </el-row>-->

    </el-form>
  </div>
</template>

<script>
import FormError from "@/components/form/FormError";
import * as moment from "moment"
import Tag from "./Tag"
import TitleAndDescription from "./TitleAndDescription";
import { mapGetters } from "vuex";

export default {
  components: {
    FormError,
    TitleAndDescription,
    Tag

  },
  props: ["field"],
  data() {
    return {
      validations: [],
      date: ""
    };
  },
  computed:{
    ...mapGetters("company", ["getCompanySettings"]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
  },
  methods: {
    getFormat() {
      return this.getDefaultDateFormat
        ? (this.getDefaultDateFormat === 'MMMM DD YYYY' ? 'MMMM-dd-yyyy' : this.getDefaultDateFormat.replace("YYYY", "yyyy").replace("DD", "dd"))
        : 'MM-dd-yyyy';
    },
   onSelectDate() {
      this.field.value = moment(this.date).utc().format()                                                                          
    }
  },
 

};
</script>

<style lang="scss" scoped>
@media (max-width:767px){
  .el-date-editor.el-input{
    width: 150px;
  }
}


</style>